import request from '../util/request';

const apis = {
    getFirm(params) {
        return request({
            url: '/V1/listRank',
            method: 'get',
            params
        })
    },
    infoRank(params) {
        return request({
            url: '/V1/infoRank',
            method: 'get',
            params
        })

    }

}

export default apis